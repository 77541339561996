<template>
    <div class="container">
        <div class="banner">
            <img :src="banner">
        </div>
        <div class="main">
            <div class="tab">
                <ul>
                    <li v-for="(item,index) in tabList" :key="index" :class="item.active?'active':''" @click="changeTab(item,index)">{{ item.name }}</li>
                </ul>
            </div>
            <div class="content">
                <div class="content_l">
                    <div class="news_list">
                        <div class="news_list_item" v-for="(item,index) in newsList" :key="index" @click="changToDetail(item.article_id)">
                            <div class="item_l">
                                <span class="month">{{ getMonth(item.updated_at) }}</span>
                                <span class="year">{{ getYear(item.updated_at) }}</span>
                            </div>
                            <div class="item_c">
                                <span class="title">{{ item.title }}</span>
                                <p>
                                    {{ item.summary }}
                                </p>
                                <span class="check">查看详情</span>
                            </div>
                            <div class="item_r">
                                <img v-lazy="item.cover_image">
                            </div>
                        </div>
                    </div>
                    <!-- 分页组件 -->
                    <paginate
                        :page-count="total"
                        :click-handler="changPage"
                        :prev-text="'上一页'"
                        :next-text="'下一页'"
                        :container-class="'className'"
                        :page-class="'page-item'">
                    </paginate>
                </div>
                <div class="content_r">
                    <div class="title">热门资讯</div>
                    <div class="hot">
                        <span v-for="(item,index) in hotList" :key="index" @click="changToDetail(item.article_id)">{{ item.title }}</span>
                    </div>
                    <div class="title">最新活动</div>
                    <div class="card_list">
                        <div class="card_list_item" v-for="(item,index) in newList" :key="index" @click="changToDetail(item.article_id)">
                            <div class="card_top">
                                <img v-lazy="item.cover_image">
                            </div>
                            <div class="card_footer">
                                <span class="text">{{ item.title }}</span>
                                <span class="time">{{ formatData(item.updated_at) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loading v-if="isLoading"/>
    </div>
</template>
<script>
import { getNews,getNewsBanner } from '@/api/news'
import paginate from 'vuejs-paginate'
import moment from 'moment';
export default {
    name: 'news',
    components: {
        paginate
    },
    data() {
        return {
            tabList: [
                { name: '运东新闻',active: true },
                { name: '行业新闻',active: false },
                { name: '社会政策',active: false }
            ],
            newsList: [],
            query: {
                page: 1,
                per_page: 10,
                category: '运东新闻',
                is_hot: 0
            },
            total: 0,
            hotList: [],
            newList: [],
            isLoading: true,
            banner: ''
        }
    },
    mounted() {
        this.initData()
        let hotParam = {
            page: 1,
            per_page: 5,
            category: '',
            is_hot: 1
        }
        this.initRightData(hotParam,'hotList')
        let newParam = {
            page: 1,
            per_page: 3,
            category: '最新活动',
            is_hot: 0
        }
        this.initRightData(newParam,'newList')
    },
    methods: {
        async initData() {
            try {
                const banner = await getNewsBanner()
                const news = await getNews(this.query)
                Promise.all([banner, news]).then(res => {
                    if(res[0].code == 200) {
                        this.banner =  res[0].data.banner
                    }
                    if(res[1].code == 200) {
                        this.total = Math.ceil(res[1].total / this.query.per_page)
                        this.newsList = res[1].data
                    }
                })
                
            }finally {
                this.isLoading = false
            }
        },
        async initRightData(query,type) {
            const res = await getNews(query)
            if(res.code == 200) {
                this[type] = res.data
            }
        },
        changeTab(item,i) {
            this.tabList.forEach((item,index) => {
                if(i == index) {
                    item.active = true
                }else {
                    item.active = false
                }
            })
            this.query.category = item.name
            this.query.page = 1
            this.initData()
        },
        //分页
        changPage(pageNum) {
            this.query.page = pageNum
            this.initData()
        },
        changToDetail(id) {
            this.$router.push(`/newsDetail?id=${id}`)
        }
    },
    computed: {
        getYear() {
            return (date) => {
                return moment(date).year()
            }
        },
        getMonth() {
            return (date) => {
                return moment(date).format('MM-DD')
            }
        },
        formatData() {
            return (date) => {
                return moment(date).format('YYYY-MM-DD')
            }
        }
    }
}
</script>
<style scoped lang="scss">
$width: 1600px;
.container {
    box-sizing: border-box;
    width: 100vw;
    height: auto;
    background-color: #f5f5f5;
    .banner {
        width: 100%;
        height: 530px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .main {
        width: $width;
        margin: 0 auto;
        padding: 30px 50px 60px 50px;
        box-sizing: border-box;
        .tab {
            color: #333333;
            font-size: 18px;
            ul {
                display: flex;
                column-gap: 55px;
                li {
                    cursor: pointer;
                    transition: all 0.3s ease;
                }
                .active {
                    color: #FFA71D;
                    position: relative;
                    &::after {
                        content: '';
                        width: 70px;
                        height: 4px;
                        background: #FFA71D;
                        position: absolute;
                        left: 50%;
                        bottom: -12px;
                        transform: translateX(-50%);
                        border-radius: 2px;
                    }
                }
            }
        }
        .content {
            margin-top: 30px;
            display: flex;
            column-gap: 20px;
            &_l {
                flex: 1;
                height: 100%;
                background: #fff;
                padding: 0 30px;
                box-sizing: border-box;
                .news_list {
                    &_item {
                        display: flex;
                        align-items: center;
                        column-gap: 30px;
                        border-bottom: 1px solid #EEEEEE;
                        padding: 30px 0;
                        box-sizing: border-box;
                        cursor: pointer;
                        .item_l {
                            width: 130px;
                            display: flex;
                            flex-direction: column;
                            row-gap: 30px;
                            .month {
                                color: #666;
                                font-size: 22px;
                            }
                            .year {
                                color: #999;
                                font-size: 14px;
                            }
                        }
                        .item_c {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            row-gap: 18px;
                            color: #333333;
                            .title {
                                font-size: 22px;
                                &:hover {
                                    color: #FFA71D;
                                }
                            }
                            p {
                                font-size: 14px;
                                color: #666;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; /* 控制显示的行数 */
                                -webkit-box-orient: vertical;
                            }
                            .check {
                                font-size: 16px;
                            }
                        }
                        .item_r {
                            width: 220px;
                            height: 160px;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: all 0.3s ease;
                                &:hover {
                                    transform: scale(1.1);
                                }
                            }
                        }
                    }
                }
                .className {
                    display: flex;
                    column-gap: 10px;
                    justify-content: flex-end;
                    padding: 20px 0;
                    box-sizing: border-box;
                    ::v-deep li {
                        padding: 2px 8px;
                        box-sizing: border-box;
                        border: 1px solid #EEEEEE;
                        color: #666;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        -webkit-user-select: none; /* Safari */
                        -moz-user-select: none; /* Firefox */
                        -ms-user-select: none; /* IE 10+/Edge */
                        user-select: none;
                    }
                    ::v-deep .page-item{
                        width: 30px;
                        height: 30px;
                    }
                    ::v-deep .active {
                        background-color: #ffa71d;
                        color: #fff;
                    }
                }
                
            }
            &_r {
                // position: sticky;
                // top: 0;
                width: 360px;
                .title {
                    color: #333333;
                    font-weight: bold;
                    font-size: 18px;
                    margin-bottom: 20px;
                }
                .hot {
                    width: 100%;
                    padding: 30px 20px;
                    box-sizing: border-box;
                    color: #666;
                    background: #fff;
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    margin-bottom: 30px;
                    font-size: 14px;
                    span {
                        white-space: nowrap; /* 禁止换行 */
                        overflow: hidden; /* 隐藏溢出内容 */
                        text-overflow: ellipsis; /* 显示省略号 */
                        cursor: pointer;
                        &:hover {
                            color: #FFA71D;
                        }
                    }
                }
                .card_list {
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    &_item {
                        background: #fff;
                        cursor: pointer;
                        .card_top {
                            overflow: hidden;
                            height: 200px;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: all 0.3s ease;
                                &:hover {
                                    transform: scale(1.1);
                                }
                            }
                        }
                        .card_footer {
                            padding: 16px 20px;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            row-gap: 15px;
                            .text {
                                color: #333333;
                                font-size: 16px;
                                font-weight: bold;
                                &:hover {
                                    color: #FFA71D;
                                }
                            }
                            .time {
                                color: #999999;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
